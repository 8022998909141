.testTaille {
    position: absolute;
    width: 60px;
    height: 30px;
    top: 40%;
    left: 40%;
    background-color: yellow;
}
.div {
    width: 100%;
    height: 100%;
    .titleImage {
        margin-top: 10rem;
        height: 18rem;
        object-fit: cover;
        border-radius: var(--radius10);
    }
}
