.btn {
    padding: 5px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s opacity;
    &:hover {
        opacity: 0.8;
    }
}

.btn-primary {
    background-color: var(--color-text-secondary);
    color: var(--color-text);
    border: 2px solid var(--lightersecondary);
}

.btn-reverse-primary {
    border: 2px solid var(--primary);
    color: var(--primary);
    background-color: var(--backgroundWhiteBtn);
}

.card {
    border-radius: 20px;
    background-color: var(--backgroundWhite);
}

.text-primary {
    color: var(--primary);
}
.active {
}
