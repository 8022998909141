:root {
    font-size: 62.5%;
}

body {
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background: linear-gradient(0.25turn, #006eeb, #5cfa99);
}

h1,
h2,
h3,
h4 {
    margin: 0;
}
p {
    margin: 0;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding-left: 0px;
    margin: 0;
}
a {
    color: var(--text-color);
    text-decoration: none;
}

img {
    max-width: 100%;
}

input {
    font-family: var(--font-family);
}

::placeholder {
    font-size: var(--font-family);
}

.material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
}

.material-icons-rounded {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
