.d-flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-fill {
    flex: 1 1 auto;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.flex-row-center-all {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}
.flex-row-nowrap-center-all {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.flex-column-nowrap-center-all {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
