:root {
    --border: 1px solid rgb(76, 74, 74);
    --borderSecondary: 2px solid var(--secondary);
    --radius: 4px;
    --dark-grey: rgb(76, 74, 74);
    --dark-primary: #2a2257;
    --cool-primary: #3e20aa;
    --secondary: #8b45e1;
    --dark-turquoise: #226668;
    --cool-turquoise: #6dfcd0;
    --dark-blue: #17186b;
    --cool-blue: #1061f0;
    --dark-green: #007e00;
    --cool-green: #00fd62;
    --dark-red: #da5657;
    --medium-red: #ec7f73;
    --cool-red: #faa58f;

    // --font-family: "Roboto Slab", serif;
    --font-family: "Roboto Condensed", sans-serif;
    --text-diary: "sacramento", serif;

    //mot croisé
    --squareWH: 65px;
    --squareFontSize: 1rem;
    --squareFontSizeInput: 4rem;
    --varArrow: 15px;

    //couleur métro
    --orange-border: #ff8810;
    --blue-logo: #091b5b;
    --backgroundWhite: white;
    --backgroundWhiteBtn: white;

    //radius
    --radius10: 10px;
}
